@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.search-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap:calcRem(8px);
    //padding: calcRem(10px);

    @include media-breakpoint-down(md) {
        //flex-wrap: wrap;
    }

    .custom-input{
        @include media-breakpoint-down(xl) {
            margin-bottom: calcRem(-3px) !important;
        }
    }

    .input-group{
        max-width: calcRem(320px);
        flex-wrap: nowrap;
        align-items: start;
        align-self: start;
        .input{
            flex-grow: 1;
            min-width: calcRem(100px);
        }
        &-btn{
            width: 0;
            height: 0;
        }
    }

    &__controls{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        width: auto;
        max-height: calcRem(49px);
        cursor: pointer;

        &__direction{
            padding: calcRem(6px) calcRem(12px) calcRem(6px) calcRem(12px);
            @include media-breakpoint-down(md) {
                padding: calcRem(3px) calcRem(6px);
            }

        }
        &__pills{
            padding-left: calcRem(12px);
            display: flex;
            flex-direction: column;
            //min-height: 72px;
            .pills {
                padding: calcRem(3px) calcRem(6px);
                font-size: 10px;
                margin: 0 calcRem(8px) calcRem(2px) 0;
            }
            img{
                width: 7px;
            }
        }
        &__vl{
            border-left: calcRem(1px) solid $black;
            height: 100%;
            min-height: calcRem(48px);
        }
        &__icon-group{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            font-size: $fontSizeSm;
            font-weight: bold;
            color: $blue;
            filter: grayscale(100%);
            &--active{
                filter: none;
            }
            &__text{
                min-width: calcRem(33.44px);
                text-align: center;
                &--asc{
                    color: $blue;
                }
            }
            &__icon{
                height: calcRem(19px);
                padding: calcRem(2px);
                &--asc{
                    transform: rotate(-180deg);
                    color: $blue;
                }
            }
        }
    }

    .dropdown a.dropdown-item {
        font-size: calcRem(13px);
    }

    .dropdown .dropdown-menu.show {
        width: calcRem(250px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .btn.btn-search {
        position: relative;
        left: calcRem(-32px);
        top: calcRem(5px);
        border-radius: calcRem(100px);
        height: calcRem(35px);
        width: calcRem(34px);
        background-color: $blue;
        color: $white;
        z-index: 999;
        padding:calcRem(2px) calcRem(2px) calcRem(6px) calcRem(2px);
        box-shadow: -3px 0px 5px #00000029;
    }
    img.search-icon {
        height: calcRem(9px);
        padding: 0 calcRem(2px);
        filter: brightness(0) invert(1);
    }

    .dropdown-toggle.btn.btn-success {
        background-color: transparent!important;
        color: $blue;
        font-family: $fontSizeSm !important;
        font-weight: normal !important;
        font-size: calcRem(12px) !important;
        border: none!important;
        @include media-breakpoint-down(md) {
            padding: calcRem(3px) calcRem(6px) !important;
        }

    }
    .dropdown-toggle::after{
        display: none;
    }

    .dropdown-toggle.btn.btn-success:focus {
        background-color: $blue;
        color: $offwhite;
        border: none;
    }

    span.gray-clear {
        background-color: $white !important;
    }

    .dropdown a.dropdown-item:hover {
        color: $blue !important;
        background-color: $white !important;
    }

}

.filter-by {
    font-size: $fontSizeSm;
    white-space: nowrap;
}
.filter-icon {
    height: calcRem(9px);
    padding: 0 calcRem(2px);
    filter: invert(100%);
}

