@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

@mixin base-card {
  text-align: center;
  padding: calcRem(5px);
  font-size: calcRem(13px);
  writing-mode: vertical-rl;
  width: auto;
  border-bottom-left-radius: calcRem(14px);
  border-top-left-radius: calcRem(14px);
}

@mixin base-matches{
  background-color: $white;
  background-position: center center;
  background-size: cover;
  padding-top: calcRem(20px);
  padding-bottom: calcRem(20px);
  border-radius: calcRem(14px);
  -webkit-box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
  height: calc(100% - calcRem(30px));
}

.matches__graph {
  align-content: start;
  color: $black;
  font-size: calcRem($fontSizeMd);
  background-color: $white;
  width: auto;
  max-width: calcRem(430px);
  height: calcRem(430px);
  @include media-breakpoint-down(xl) {
    height: calcRem(400px);
  }

  &__title{
    margin-bottom: calcRem(20px);
  }

  svg {
    width: auto;
    max-width: calcRem(430px);
    height: auto;
  }
}

.matches__list {
  @include base-matches;
  margin-right: calcRem(15px);
}

.matches__details {
  @include base-matches;
}

.matches__title {
  color: $primaryTextColor;
  font-size: calcRem(17px);
  text-transform: uppercase;
}

.matches__description {
  color: $secondaryTextColor !important;
  font-size: calcRem(15px);
  font-weight: bold;
  margin-top: calcRem(20px);
}

.matches__button{
  background-color: $primaryActionColor !important;
  margin-top: calcRem(40px);
}

.pill {
  background-color: rgba(0,0,0,.5);
  color: white;
  border-radius: calcRem(14px);
  margin: calcRem(20px) calcRem(20px) calcRem(20px) calcRem(20px);
  cursor: pointer;
  text-transform: uppercase;

  :hover {
    background-color: rgba(0,0,0,.8);
    transition: background-color 0.15s ease-out 0s;
    border-right: inherit;
    border-bottom-right-radius: inherit;
    border-top-right-radius: inherit;
  }

  &__active {
    background: linear-gradient(0deg, $primaryActionColor, $secondaryActionColor);;
    color: white;
    border-radius: calcRem(14px);
    margin: calcRem(20px) calcRem(20px) calcRem(20px) calcRem(20px);
    cursor: pointer;
    text-transform: uppercase;
  }
}

.pill__contents{
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  border-bottom-left-radius: calcRem(14px);
  border-top-left-radius: calcRem(14px);
  :hover {
    background-color: rgba(0,0,0,0);
    border-radius: calcRem(14px) calcRem(0px) calcRem(0px) calcRem(14px);
  }

  .card__title{
    word-wrap: break-word;
    height: 100%;
    width: 100%;
    border-bottom-left-radius: calcRem(14px);
    border-top-left-radius: calcRem(14px);
    padding: calcRem(15px);
    align-self: center;
    text-align: center;
  }

  .card__left{
    padding: calcRem(15px);
    writing-mode: vertical-rl;
    align-self: center;
    float: left;
    height: 100%;
    width: auto;
    border-bottom-left-radius: calcRem(14px);
    border-top-left-radius: calcRem(14px);

    &__low{
      @include base-card;
      background-color: #f24921;
    }
    &__medium{
      @include base-card;
      background-color: #f5a73c;

    }
    &__high{
      @include base-card;
      background-color: #4ae368;
    }
  }
}

.pagination-controls{
  justify-content:space-between;
  display:flex;
}

.pagination-button{
  background-color: $primaryActionColor !important;
  margin-top: calcRem(20px);
  margin-left: calcRem(30px);
  margin-right: calcRem(30px);
  width: calcRem(120px);
}
