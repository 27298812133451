/* Lightbox.css */
.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.lightbox-content {
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  padding: 20px;
  border-radius: 8px;
}

.lightbox-content img {
  width: 100%;
  height: auto;
  display: block;
  max-width: 100%;
  max-height: 100%;
}