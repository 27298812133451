@import "../../styles/functions";
@import "../../styles/variables";


.accordion {

    .accordion-icon {
        width: calcRem(20px);
        height: calcRem(20px);
    }

    &#action-header button.accordion-button {
        color: $black;
        font-family: $primaryFont;
        font-weight: $medium;
        padding: calcRem(10px);
        transition: color 0.13s ease-in;
        border-radius: calcRem(100px);
        font-size: calcRem($fontSizeMd);
    }

    &#action-header button.accordion-button:hover{
        background: $offwhite;
    }

    &#action-header button.accordion-button:not(.collapsed) {
        background: $offwhite;
        font-weight: $bold;
        box-shadow: none;
    }

    .accordion-button::after {
        transform: rotate(-90deg);
        width: calcRem(15px);
        height: calcRem(15px);
        background-size: contain;
        background-image: url(../../assets/images/design_assets/arrow-down-icon.svg) !important;
    }

    .accordion-button:not(.collapsed)::after {
        width: calcRem(15px);
        height: calcRem(15px);
        background-size: contain;
        background-image: url(../../assets/images/design_assets/arrow-down-icon.svg) !important;
        transform: rotate(0deg) translate(calcRem(-3px), calcRem(4px));
    }

    .accordion-body {
        padding: calcRem(14px);
    }

    .accordion-icon{
        margin-right: calcRem(24px);
    }

}
