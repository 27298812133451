@import "../../styles/functions";
@import "../../styles/variables";

.file-picker {
  margin-top: calcRem(10px);
  margin-bottom: calcRem(20px);
  background-color: $offwhite;

  &__img-selected{
    border-bottom: white 3px;
  }

  &__separator{
    background-color: $white;
    display: block;
    height: calcRem(20px);
  }

  &__scrollable-section{
    max-height: calcRem(650px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__img-preview {
    width: calcRem(150px);
    height: calcRem(150px);
    object-fit: cover;
    border-radius: calcRem(8px);
    @media screen and (max-width: 477px) {
      width: calcRem(115px);
      height: calcRem(115px);
      align-self: center;
    }
    @media screen and (max-width: 385px) {
      width: calcRem(100px);
      height: calcRem(100px);
      align-self: center;
    }
  }

  &__img-delete {
    width: calcRem(30px);
    height: calcRem(30px);
    font-size: calcRem(30px);
    position: relative;
    transform: translateX(140px) translateY(calcRem(10px));
    &:hover {
      cursor: pointer;
    }
    svg {
      margin-top: calcRem(-20px);
    }
    @media screen and (max-width: 477px) {
      transform: translateX(100px) translateY(calcRem(10px));
    }
    @media screen and (max-width: 385px) {
      transform: translateX(80px) translateY(calcRem(10px));
    }
  }

  &__img-title{
    font-size: $fontSizeMd;
    color: $grey;
    max-width: calcRem(150px);
    text-align: center;
    margin-top: calcRem(10px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .form-label {
    font-size: $fontSizeSmall;
    padding-left: calcRem(5px);
  }


  .error {
    border: thin solid $red!important;
  }

  .error-lbl {
    color: $red;
  }

  p{
    font-style: italic
  }

  .click-to-view{
    border-radius: calcRem(5px);
    height: calcRem(30px);
    width: calcRem(30px);
  }
  iframe{
    pointer-events: none;
  }
  .sort-button {
    margin-left: 8px;
    background: $actionColor;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    color: $white;
    padding: 0 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  }

  .sort-button.ascending::after {
    content: "↑";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
  }

  .sort-button.descending::after {
    content: "↓";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
  }

  .loading {
    height: 80vh;
  }
}
