@import "./../../styles/global";
@import "./../../styles/variables";
@import "./../../styles/functions";
@import "~bootstrap/scss/bootstrap";

.eme-modal {
    padding: calcRem(10px);
    .modal-dialog {
        width: 100%;
        max-width: calcRem(800px);
        height: 90vh;
        max-height: 90vh;

    }
    .modal-body{
        padding: 0 calcRem(10px) calcRem(10px) calcRem(10px);

    @include media-breakpoint-only(xs) {
        overflow-y: auto;
        overflow-x: hidden;
    }
    }
    .modal-content {
        background-color: $foregroundColor;
        border-radius: calcRem(8px);
        max-height: 90vh;
        padding: calcRem(10px);
        margin-right: calcRem(15px);
        overflow-y: auto;
    }
    .modal-header {
        border-bottom: 0;
        margin-bottom: calcRem(15px) !important;
    }
    .modal-title {
        color: $black;
        text-transform: uppercase;
        font-family: $primaryFont;
        font-weight: $regular;
    }
    .modal-time {
        color: $black;
        font-family: $primaryFont;
        text-transform: uppercase;
        padding-top: calcRem(15px);
    }
    .modal-spots {
        color: $foregroundColor;
        font-family: $primaryFont;
        font-size: $fontSizeExtraSmall;
    }
    .modal-price {
        color: $black;
        font-family: $primaryFont;
        font-weight: $medium;
        font-size: $fontSizeExtraSmall;
    }
    .modal-description {
        color: $black;
        font-family: $primaryFont;
        font-weight: $regular;
    }
    .modal-footer {
        border-top: 0;
        justify-content: flex-start !important;
        z-index: 2;
        .btn-secondary {
            background: $actionColor;
            color: $white !important;
            border-radius: 0;
            border: none;
            padding-left: calcRem(25px);
            padding-right: calcRem(25px);
            font-family: $primaryFont;
            font-size: $fontSizeSmall;
            text-transform: uppercase;
        }
    }

    .dropdown-menu {
        transform: translate3d(0, 0, 0) !important;
        position: relative !important;
    }
}
