@function calcRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: calcRem($size);
}

.btn-primary {
  background-color: #EA2D31;
}
