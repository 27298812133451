@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.pills {
  border: 1px solid #000000;
  border-radius: calcRem(6px);
  padding: calcRem(4px) calcRem(8px) calcRem(4px) calcRem(8px);
  margin: 0 calcRem(8px) calcRem(8px) 0;
  display: inline-flex;
  align-items: center;
  min-height: calcRem(26px);
  height: auto;
  vertical-align: top;
  font-size: calcRem($fontSizeSm);
  width: auto;
  
  &__hover:hover{
    border: 1px solid $blue;
    color: $blue;
    cursor: pointer;
    img{
      filter: none !important;
    }
  }

  &__white{
    background-color: $white;
    color: $black;
    &__icon {
      margin-right: 8px;
      filter: brightness(0) invert(0);
      &__cancel{
        filter: brightness(0) invert(0);
      }
    }
  }
  

  &__black{
    background-color: $black;
    color: $white;
    &__icon {
      margin-right: 8px;
      filter: brightness(0) invert(1);
      &__cancel{
        filter: brightness(0) invert(1);
      }
    }
  }
  
  &.cancellable {
    cursor: pointer;
  }
}

@include media-breakpoint-down(xl) {
  .pills{
    min-height: calcRem(20px);
    height: auto;
  }
}