@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.tabs {
  font-family: $primaryFont;
  font-size: $fontSizeMd;
  width: 100%;

  &__header{
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__content{
    background-color: $white;
    padding: calcRem(25px) calcRem(21px) calcRem(25px) calcRem(21px);
  }
  &__icon{
    margin-right:calcRem(20px);
  }
}


.tab {
  color: $grey;
  background-color: $offwhite;
  padding: calcRem(20px) calcRem(31px) calcRem(25px) calcRem(31px);
  text-transform: capitalize;
  cursor: pointer;

  &.active{
    color: $blue;
    background-color: $white;
    border-radius: calcRem(6px) calcRem(6px) 0 0;
    font-weight: $bold;
  }
  &:not(.active) {
    .tabs__icon {
      filter: grayscale(100%);
    }
  }
}

@include media-breakpoint-down(xl) {
  .tabs{
    font-size: $fontSizeMobileMd;
  }
  .tab {
    padding: calcRem(7px) calcRem(10px) calcRem(12px) calcRem(10px);
  }
}




