@import "../styles/variables";
@import "../styles/functions";


.error-404{
  height:100vh;
  background-color: $errorPageBackground;
  color:white;
  overflow: hidden;
}

.error-404-container{
  margin-top: 20vh!important;
}
.image{
  width:calcRem(160px);
  height:calcRem(160px);
}

.e404{
  font-size:calcRem($fontSizeExtraLarge);
  font-weight: $bold;
}

.message{
  font-size:calcRem($fontSizeMedium);
}

.back-button {
  background-color: $errorPageButton!important;
  color:$white!important;
  padding: calcRem(10px) calcRem(20px);
}

.back-button:hover {
    transition: background 30ms 30ms linear;
    background: $errorPageButtonHover!important;

}
