@import "../../../styles/functions";
@import "../../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.proposal-card {
  position: relative;
  display: flex;
  align-items: center;
  padding: calcRem(20px) calcRem(20px) calcRem(20px) calcRem(10px);
  border: 1px solid var(--backgroundColor);
  border-radius: calcRem(8px);
  background-color: var(--backgroundColor);
  margin: calcRem(8px);
  &__info{
    position: relative;
    display: inline-block;
  }
  &__info .info-box{
    visibility: hidden;
    width: calcRem(200px);
    background-color: #f9f9f9;
    color: #000;
    text-align: center;
    border-radius: calcRem(6px);
    padding: calcRem(5px) 0;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the element */
    left: 10%;
    margin-left: calcRem(-100px); /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
    font-size: $fontSizeSm;
  }

  &__info:hover .info-box {
    visibility: visible;
    opacity: 1
  }
  @include media-breakpoint-down(md) {
    padding-top: calcRem(50px);
    flex-direction: column;
  }
  .proposal-card-icon {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calcRem(40px);
    height: calcRem(40px);
    margin-right: calcRem($fontSizeXLg);
    margin-left: calcRem($fontSizeXLg);
  }

  .proposal-card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media-breakpoint-down(md) {
      margin-top: calcRem(6px);
      margin-bottom: calcRem(10px);
    }
    &.no-links {
      .proposal-card-description {
        margin-bottom: 0;
      }
    }

    .proposal-card-title {
      margin: 0;
      font-size: calcRem($fontSizeXLg);
      font-weight: bold;
      color: $black;

      @media only screen and (max-width: 768px){
        font-size: calcRem($fontSizeMd);
      }
    }

    .proposal-card-description {
      margin: calcRem(5px) 0;
      font-size: calcRem($fontSizeMd);
      color: $grey;

      @media only screen and (max-width: 768px){
        font-size: calcRem(10px);
      }
    }

    .proposal-card-links {
      display: flex;
      font-size: calcRem($fontSizeMd);
      gap: calcRem(20px);
      .details-link {
        color: $blue;
        border: 0;
        background-color: transparent;
        padding-left:0;
        padding-right:0;
      }
      .green-report-link {
        color: $green;
        border: 0;
        background-color: transparent;
        padding-left:0;
        padding-right:0;
      }
      .details-link, .green-report-link {
        text-decoration: underline;
        &:hover {
          filter: brightness(85%);
        }
      }
    }
  }

  .proposal-card-button {
    @include media-breakpoint-up(md) {
      margin-top: calcRem(38px);
    }
    button {
      padding: calcRem(4px);
      background-color: $green;
      color: $white;
      border: none;
      border-radius: calcRem(4px);
      cursor: pointer;
      &:hover {
        background-color: var(--primaryActionColor);
      }
    }
    .proposal-card-review-button {
      background-color: $white;
      font-size: calcRem(14px);
      padding: calcRem(5px);
      box-shadow: 0 calcRem(2px) calcRem(3px) $shadow-black-shady;
      color: $blue;
      border-color: $blue;
      border-width: calcRem(1px);
      border-radius: calcRem(4px);
      border-style: solid;
      cursor: pointer;
      &:hover {
        background-color: $blue;
        color: $white;
      }
    }
    .proposal-card-download-button {
      width: calcRem(46px);
      height: calcRem(46px);
      cursor: pointer;
      &:hover {
        filter: brightness(90%);
      }
    }
  }

  .proposal-card-badge {
    position: absolute;
    top: calcRem(10px);
    right: calcRem(0px);
    padding: calcRem(5px) calcRem(10px);
    background-color: $white;
    color: $green;
    border-radius: calcRem(4px) 0 0 calcRem(4px);
    font-size: calcRem($fontSizeXLg);
    font-weight: 600;
    box-shadow: 0 calcRem(2px) calcRem(4px) $shadow-black;
    @media only screen and (max-width: 768px){
      font-size: calcRem($fontSizeMd);
    }
  }
}
