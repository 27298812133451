@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

  .middle {
    height:100%;
    display:flex;
    flex-direction: column;
  }
  .dot{
    margin-top:calcRem(5px);
    margin-bottom:calcRem(5px);
  }
  .vl {
    border-left: calcRem(3px) solid $successColor;
    height: 100%;
    margin-left: calcRem(6px);
    margin-top: calcRem(3px);
    margin-bottom: calcRem(3px);
  }
  .before{
    background-color: $before;
    margin-right:calcRem(15px);
  }
  .after{
    background-color: $after;
    margin-right: calcRem(15px);
  }
.changes {
  margin-bottom: calcRem(15px);
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  word-break: break-word;
}

.timeline{
  &__title{
    font-size: calcRem($fontSizeMd);
    font-weight: $bold;
    color: $black;
    margin-bottom: calcRem(20px);
  }
  &__container{
    background-color: $offwhite;
    padding: calcRem(20px);
    border-radius: calcRem(6px);
    height: calcRem(350px);
    overflow-y: scroll;
  }
}

.timeline-accordion {

  :hover, :active{
    background-color: $white !important;
    border-radius: 0 !important;
    color: $blue !important;
    border: 0 !important;
  }
  :not(:hover),
  :not(:active){
    background-color: $white !important;
    border-radius: 0 !important;
    color: $grey !important;
    border: 0 !important;
  }
  .collapsed {
    color: $grey !important;
    border-radius: 0 !important;
    border: 0 !important;

  }
  :not(.collapsed){
    color: $blue !important;
    border-radius: 0 !important;
    border: 0 !important;
  }
}

