@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../../../node_modules/bootstrap/scss/bootstrap";

.green-report {
  &__container {
    padding: calcRem(20px);
  }

  &__download {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  &__header {
    font-size: calcRem($fontSizeXXLg);
    font-weight: bold;
    color: $green;
  }

  &__subheader {
    font-size: calcRem($fontSizeXLg);
    color: $grey;
  }

  &__info {
    font-size: calcRem($fontSizeMd);
  }

  &__body-title {
    font-size: calcRem($fontSizeMd);
    font-weight: bold;
  }

  &__body-container {
    padding: calcRem(20px);
    background-color: $offwhite;
  }

  &__blue-line {
    border-bottom: calcRem(3px) solid $blue;
    margin-bottom: calcRem($fontSizeXLg);
  }

  &__body-item-title {
    font-size: calcRem($fontSizeXXLg);
    font-weight: bold;
  }
  &__body-item-title-green {
    font-size: calcRem($fontSizeXXLg);
    font-weight: bold;
    color: $green;
  }

  &__body-color {
    &__809DAD {
      background-color: #809DAD;
      border: calcRem(3px) solid;
      min-height: calcRem(30px);
      max-width: calcRem(30px);
    }

    &__D2E8F4 {
      background-color: #D2E8F4;
      border: calcRem(3px) solid;
      min-height: calcRem(30px);
      max-width: calcRem(30px);
    }

    &__1488C7 {
      background-color: #1488C7;
      border: calcRem(3px) solid;
      min-height: calcRem(30px);
      max-width: calcRem(30px);
    }

  }
  &__body-items-small{
    font-size: calcRem($fontSizeMd);
    color: $grey;
  }
  &__chart-section{
    display:flex;
    justify-content: flex-end;
  }

  &__impact{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  &__footer{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    img{
      width: calcRem(27px);
      height: calcRem(27px);
    }
  }

}


.seed-icon {
  fill: $green;
  width: calcRem(270px);
  height: calcRem(270px);
}



.bolt-icon {
  background-image: url(../../../assets/images/design_assets/bolt.svg);
  background-repeat: no-repeat;
  background-position: left center;
}

.page-break {
  page-break-before: always;
}

.cloud-icon {
  background-image: url(../../../assets/images/design_assets/cloud.svg);
  background-repeat: no-repeat;
  background-position: left center;
}

