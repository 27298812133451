@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.image-button{
  color: $grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  &:active{
    color: $blue;
  }
  &__label{
    font-family: $primaryFont;
    font-size: calcRem($fontSizeSm);
  }
  &__icon{
    filter: grayscale(100%);
    width: calcRem(20px);
    height: calcRem(20px);
    &:active{
      filter: none;
    }
  }
  &__circular-icon{
    filter: grayscale(100%);
    width: calcRem(20px);
    height: calcRem(20px);
    border-radius: 33%;
    &:active{
      filter: none;
      border: 2px solid $secondaryActionColor;
    }
  }
}

@include media-breakpoint-down(xl) {
  .image-button{
    &__label{
      font-size: calcRem($fontSizeMobileMd);
    }
  }
}
