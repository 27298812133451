@import "variables";
@import "./functions";

a {
    text-decoration: none !important;
}

hr {
    background-color: $foregroundColor!important;
}
.blue-text {
    color: $primaryActionColor;
}
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0 !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}
