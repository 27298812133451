@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.return{
  &__container{
    text-align: center;
    border: 1px solid #ddd;
  }

  &__title{
    font-weight: bold;
  }


  &__divider {
    border: 1px solid #ddd;
    margin: 20px 0;
  }
}

.loading-container{
  .loading{
    height: 100vh;
  }
}