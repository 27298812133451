
/* ClientProfile.scss */
@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.client-section {
  width: 100%;
  padding: calcRem(15px);
  background-color: $foregroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: calcRem(8px);
  @include media-breakpoint-down(xl) {
    border-radius: calcRem(3px);
  }

  &--content-position{
    position: relative;
    bottom:calcRem(30px);
    margin-bottom: calcRem(-45px);
    margin-top: calcRem(-60px);
  }

  &__box--grey{
    background-color: $backgroundColor;
    padding: calcRem(100px) calcRem(130px);
    box-shadow: calcRem(0px) calcRem(6px) calcRem(6px) rgba(0, 0, 0, 0.2);
    border-radius: calcRem(8px);
    border: calcRem(1px) solid $grey-border;

    @include media-breakpoint-down(xl) {
      padding: calcRem(10px);
      border-radius: calcRem(3px);
    }
    @media (min-width: 1500px){
      margin-left: calcRem(200px);
      margin-right: calcRem(200px);
    }
    @media (max-width: 1500px) and (min-width: 1200px){
      margin-left: calcRem(100px);
      margin-right: calcRem(100px);
    }
  }

  &__logo--position{
    display: flex;
    justify-content: center;
    position: relative;
    top: calcRem(90px);
  }

  &__logo--frame{
    height: calcRem(259px);
    width: calcRem(259px);
    @include media-breakpoint-down(md) {
      height: calcRem(136px);
      width: calcRem(136px);
    }
  }

  &__logo{
    width: 100%;
    background-color: $foregroundColor;
    height: calcRem(259px);
    object-fit: cover;
    object-position: center;
    border-radius: calcRem(1000px);
    box-shadow: calcRem(0px) calcRem(6px) calcRem(6px) rgba(0, 0, 0, 0.2);
    @include media-breakpoint-down(md) {
      height: calcRem(136px);
    }
  }

  &__items--position{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include media-breakpoint-down(md) {
      margin-top:calcRem(80px);
    }
  }

  &__items--position-second{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  h2 {
    @include media-breakpoint-down(xl) {
      font-size: calcRem(20px);
    }
  }

  h3 {
    color: $grey;
    @include media-breakpoint-down(xl) {
      font-size: calcRem(15px);
    }
  }

  &__icon{
    color: $black;
  }

  &__visit-website--styling{
    color: $grey;
  }

  &__button--position{
    display: flex;
    justify-content: flex-start;
    @media (min-width: 1500px){
      margin-left: calcRem(200px);
    }
    @media (max-width: 1500px) and (min-width: 1200px){
      margin-left: calcRem(100px);
    }
  }
}


