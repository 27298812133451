@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";


.entry-top {
  background: $white;
  color: $black;
  border-radius: calcRem(6px);
  padding: calcRem(20px);
  margin-bottom: calcRem(20px);

  &__position {
    display: flex;
    @include media-breakpoint-down(xl) {
      justify-content: center;
    }
  }

  &__frame {
    position: relative;
    margin-top: calcRem(50px);
    width: calcRem(538px);
    @include media-breakpoint-down(md) {
      width: calcRem(226px);
    }
  }

  &__image {
    border-radius: calcRem(8px);
    height: calcRem(538px);
    object-fit: cover;
    object-position: center;
    width: 100%;
    box-shadow: 0 7px 6px #00000029;
    @include media-breakpoint-down(md) {
      height: calcRem(226px);
    }
    &__container {
      position: relative;
      max-width: calcRem(630px);
      min-width: calcRem(610px);
      max-height: calcRem(712px);
      overflow: hidden;
      @include media-breakpoint-down(md) {
        width: calcRem(300px);
        height: calcRem(300px);
      }
    }
    &__full-size{
      position: absolute;
      cursor: pointer;
      bottom: calcRem(20px);
      right: calcRem(15px);
      width: calcRem(39px);
      height: calcRem(39px);
      @include media-breakpoint-down(md) {
        bottom: calcRem(15px);
      }
    }
  }

  &__qr-code {
    position: absolute;
    width: calcRem(120px) !important;
    height: calcRem(120px);
    top: 0;
    transform: translateX(380%) translateY(-50%);
    @include media-breakpoint-down(md) {
      width: calcRem(111px);
      height: calcRem(111px);
      transform: translateX(112%) translateY(-50%);
    }
    svg {
      width: 100%;
      height: 100%;
      background-color: $white;
      padding: calcRem(10px);
    }
    &__download {
      position: absolute;
      bottom: 0;
      transform: translateX(260%) translateY(50%);
      cursor: pointer;
      img{
        width: calcRem(35px);
        height: calcRem(35px);
      }
      }
  }
}

.button-toggle-timeline {
  display: flex;
  justify-content: space-around;
}

.test {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.entry-category {
  font-family: $primaryFont;

  &__container {
    padding: calcRem(20px);
    background-color: $white;
    margin-top: calcRem(20px);
    margin-bottom: calcRem(20px);
    border-radius: calcRem(6px);
  }

  &__title {
    font-size: calcRem(20px);
    font-weight: bold;
    width: auto;
    color: $black;
    margin-bottom: calcRem(20px);
  }

  &__label {
    font-size: calcRem($fontSizeMd);
    width: auto;
    font-weight: $bold;
    color: $black;
    margin-bottom: calcRem(10px);
  }

  &__sub-label {
    font-size: calcRem($fontSizeMd);
    color: $grey;
    margin-bottom: calcRem(40px);
  }
}

.offwhite-container {
  background-color: $offwhite;
  padding-top: calcRem(20px);
  padding-bottom: calcRem(20px);
  border-radius: calcRem(6px);
}

.blur-block {
  filter: blur(18px);
}

.sign-in-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 99; /* Ensure it is on top */
}