@import "../../styles/functions";
@import "../../styles/variables";

.landing-page {
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Ensure parent container covers full viewport height
}

.main-content {
  flex: 1;  // This allows the main content to take up available space
}

.slider-container {
  width: 100%;
  margin: auto;
  position: relative;
}

.slide {
  position: relative;
}

.image-container {
  position: relative;
  width: 100%;
  height: 40vh;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.overlay-text {
  position: absolute;
  top: 40%;
  left: 30%;
  transform: translate(-50%, -50%);
  color: black;
  padding: 10px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 2vw;
  z-index: 3;

  .subtitle {
    font-size: 0.7vw;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.slick-next, .slick-prev {
  z-index: 2;
  width: 50px;
  height: 50px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slick-next:before, .slick-prev:before {
  font-size: calcRem(32px);
  color: black;
  font-weight: bolder;
}

.slick-disabled, .slick-disabled {
  font-size: calcRem(32px);
  color: grey !important;
}

.slick-arrow.slick-next,
.slick-arrow.slick-prev {
  display: flex;
  color: black;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0.8;
}

.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:hover {
  opacity: 1;
}

.slick-prev::before, .slick-next::before {
  display: none;
}

.slick-next {
  right: 10px;
}

.slick-prev {
  left: 10px;
}

.bg-gray {
  height: 100vh;
}

.footer {
  font-size: calcRem(11.2px);
  padding: calcRem(8px);
  background-color: $backgroundColor;
  text-align: center;
  margin-top: auto;
  z-index: 1;
}

.card-container {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding-left: calcRem(32px);
  padding-right: calcRem(32px);

  &-border {
    border-radius: 6px;
  }
}

.d-sm-block.d-lg-flex.justify-content-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: calcRem(32px);
}



@media (max-width: 1200px) {
  .card-container {
    position: relative;
    text-align: left;
    padding: 0 1rem;
  }

  .image-container {
    height: 50vh;
  }

  .bg-gray {
    display: none;
  }

  .overlay-text {
    font-size: 2vw;
    padding: calcRem(5px);
    top: 50%;
    left: 50%;
    text-align: center;

    .subtitle {
      font-size: $fontSizeMobileSm;
      text-wrap: balance;
    }
  }
}

@media (max-width: 853px) {
  .card-container {
    position: relative;
    text-align: left;
    padding: 0 1rem;
  }

  .image-container {
    height: 50vh;
  }

  .bg-gray {
    display: none;
  }

  .overlay-text {
    font-size: 2vw;
    padding: calcRem(5px);
    top: 50%;
    left: 50%;
    text-align: center;

    .subtitle {
      font-size: $fontSizeMobileSm;
      text-wrap: balance;
    }
  }
}

@media (max-width: 768px) {
  .card-container {
    position: relative;
    text-align: center;
    padding: 0 1rem;
  }


  .image-container {
    height: 50vh;
  }

  .bg-gray {
    display: none;
  }

  .slick-next {
    right: calcRem(-10px);
  }

  .slick-prev {
    left: calcRem(-10px);
  }

  .overlay-text {
    font-size: 2vw;
    padding: calcRem(5px);
    top: 50%;
    left: 50%;
    text-align: center;
    width: 90%;

    .subtitle {
      font-size: $fontSizeMobileSm;
      text-wrap: balance;
    }

    h4 {
      text-wrap: balance;
    }
  }
}

@media (max-width: 592px) {
  .card-container {
    position: relative;
    padding: 0 0.5rem;
  }

  .image-container {
    height: 40vh;
  }

  .overlay-text {
    font-size: 2vw;
    padding: calcRem(3px);
    top: 50%;
    left: 50%;
    text-align: center;

    .subtitle {
      font-size: $fontSizeMobileSm;
      text-wrap: balance;
    }
  }

  .bg-gray {
    display: none;
  }

  .footer {
    margin-top: calcRem(32px);
    padding: calcRem(8px);
  }
}

@media (max-width: 480px) {
  .card-container {
    position: relative;
    padding: 0 0.5rem;
  }

  .image-container {
    height: 40vh;
  }

  .overlay-text {
    font-size: 2vw;
    padding: calcRem(3px);
    top: 50%;
    left: 50%;
    text-align: center;

    .subtitle {
      font-size: $fontSizeMobileSm;
      text-wrap: balance;
    }
  }

  .bg-gray {
    display: none;
  }

  .footer {
    margin-top: calcRem(32px);
    padding: calcRem(8px);
  }
}
.custom-col {
  width: 100%; /* Default to full width for smaller screens */
}

@media (min-width: 992px) { /* lg breakpoint */
  .custom-col {
    width: 30% !important; /* Adjust this percentage as needed */
    margin: 0 1% !important; /* Example margin to control spacing between columns */
  }
}

.bg-gray {
  height: 100dvh;

  /* Adjust margin-top for extra small screens (<576px) */
  @media (max-width: 575.98px) {
    height: calc(50% - 5rem);
  }

  /* Adjust margin-top for small screens (≥576px and <768px) */
  @media (max-width: 767.98px) and (min-width: 576px) {
    height: calc(50% - 5rem);
  }

  /* Adjust margin-top for medium screens (≥768px and <992px) */
  @media (max-width: 991.98px) and (min-width: 768px) {
    height: calc(50% - 5rem);
  }

  /* Adjust margin-top for large screens (≥992px and <1200px) */
  @media (max-width: 1199.98px) and (min-width: 992px) {
    height: calc(30% - 5rem);
  }

  @media (min-width: 1200px) {
    height: 100vh;
  }

  @media (min-width: 1400px) {
    height: 90vh;
  }

  @media (min-width: 1600px) {
    height: 95vh;
  }

  @media (min-width: 1800px) {
    height: 90vh;
  }

  @media (min-width: 2300px) {
    height: 55vh;
  }

}

.home-page-footer {
  margin-top: calcRem(80px);

  /* Adjust margin-top for extra small screens (<576px) */
  @media (max-width: 575.98px) {
    margin-top: calcRem(64px);
  }

  /* Adjust margin-top for small screens (≥576px and <768px) */
  @media (max-width: 767.98px) and (min-width: 576px) {
    margin-top: calcRem(80px);
  }

  /* Adjust margin-top for medium screens (≥768px and <992px) */
  @media (max-width: 991.98px) and (min-width: 768px) {
    margin-top: calcRem(32px);
  }

  /* Adjust margin-top for large screens (≥992px and <1200px) */
  @media (max-width: 1199.98px) and (min-width: 992px) {
    margin-top: calcRem(32px);
  }

  @media (min-width: 1200px) {
    margin-top: calcRem(160px);
  }

  @media (min-width: 1400px) {
    margin-top: calcRem(160px);
  }

  @media (min-width: 1600px) {
    margin-top: calcRem(64px);
  }

  @media (min-width: 1800px) {
    margin-top: calcRem(80px);
  }
}

@media (max-height: 700px) {
  .card-container {
    position: relative;
    text-align: left;
    padding: 0 1rem;
  }
  .bg-gray {
    display: none;
  }
}