@import "../../../styles/functions";
@import "../../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.marketplace-wizard{
  font-size: calcRem($fontSizeSm);
  &__box{
    font-size: calcRem($fontSizeSm);
    background-color: $white;
    border-radius: calcRem(6px);
    padding: calcRem(5px) calcRem(20px) calcRem(20px) calcRem(20px);
    margin-bottom: calcRem(20px);
    &--grey{
      background-color: $offwhite;
    }
  }
  &__image-container{
    padding: 0 !important;
    background-color: $offwhite;
    margin-bottom: calcRem(20px);
    border-radius: calcRem(6px);
    &__label{
      font-size: calcRem($fontSizeLg);
      width: auto;
      font-weight: $bold;
      color: $black;
      margin-bottom: calcRem(20px);
    }
  }
  &__circle{
    width: calcRem(49px);
    height: calcRem(49px);
    border-radius: 50%;
    padding: calcRem(10px);
    background-color: $blue;
  }
  &__success-title{
    font-size: calcRem($fontSizeLg);
    font-weight: bold;
  }
}
