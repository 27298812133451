
@import "../../../styles/functions";
@import "../../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.user-profile{
  &__position--start{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  &__position--margin-extra{
    margin-top: 20px;

    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
  }
  &__input-size{
    width: 200px;
  }
  &__field{
    margin-top: 10px;
    color: $grey;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    @include media-breakpoint-down(md) {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
  &__position-button-form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__link{
    cursor:pointer;
    color:$blue!important;
    text-decoration:underline;
  }
}