@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

// Default (Blue) Button Style
.e-button {
    color: $blue;
    font-family: $primaryFont;
    font-weight: $regular;
    font-size: calcRem($fontSizeMd);
    border: 1px solid $blue;
    background-color: $white;
    padding: calcRem(6px) calcRem(12px) calcRem(6px) calcRem(12px);
    cursor: pointer;
    display: inline-block;
    box-shadow: #00000029 0 9px 8px;
    border-radius: calcRem(6px);
    text-align: center;
    min-width: calcRem(71px);

    &:not(.opaque):hover {
        background-color: $blue;
        color: $white;
    }

    &:not(.opaque):active {
        background-color: darken($blue, 10%);
        color: darken($white, 10%);
    }
}

.opaque {
    opacity: 0.5;
}

.spinner-color {
    color: $blue;
}

.e-button:hover .spinner-color {
    color: $white;
}

// Additional Variants
.black {
    border: 1px solid $black;
    color: $black;
    background-color: $white;

    &:not(.opaque):hover {
        background-color: $black;
        color: $white;
    }

    &:not(.opaque):active {
        background-color: darken($black, 10%);
        color: darken($white, 10%);
    }
}

.red {
    border: 1px solid $red;
    color: $red;
    background-color: $white;

    &:not(.opaque):hover {
        background-color: $red;
        color: $white;
    }

    &:not(.opaque):active {
        background-color: darken($red, 10%);
        color: darken($white, 10%);
    }
}

.green {
    border: 1px solid $green;
    color: $green;
    background-color: $white;

    &:not(.opaque):hover {
        background-color: $green;
        color: $white;
    }

    &:not(.opaque):active {
        background-color: darken($green, 10%);
        color: darken($white, 10%);
    }
}

.yellow {
    border: 1px solid $yellow;
    color: $yellow;
    background-color: $white;

    &:not(.opaque):hover {
        background-color: $yellow;
        color: $white;
    }

    &:not(.opaque):active {
        background-color: darken($yellow, 10%);
        color: darken($white, 10%);
    }
}

// Review Variant
.review {
    border: 1px solid $borderCautionOrange;
    color: $borderCautionOrange;

    &:not(.opaque):hover {
        background-color: $borderCautionOrange;
        color: $white;
    }

    &:not(.opaque):active {
        background-color: darken($borderCautionOrange, 10%);
        color: darken($white, 10%);
    }
}

.spinner-orange {
    color: $borderCautionOrange;
}

@include media-breakpoint-down(xl) {
    .e-button {
        font-size: calcRem($fontSizeMobileMd);
    }
}
