@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.search-engine {
  border: calcRem(1px) solid $grey-border;
  border-radius: calcRem(6px);
  font-size: calcRem($fontSizeSm);
  &-page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }

  .custom-input {
    position: relative;
  }

  search-engine.form-control {
    padding: calcRem(6px) calcRem(10px) calcRem(6px) calcRem(13px);
  }
  .form-control {
    width: 100%;
    border: thin solid $grey-border;
    border-radius: calcRem(100px) !important;
    font-size: calcRem($fontSizeMd);
  }
  &__title{
  font-size: calcRem($fontSizeLg);
    font-weight: bold;
  }
  &__title-box{
    font-size: calcRem($fontSizeMd);
    font-weight: bold;
  }
  &__item-box{
    overflow: auto;
    max-height: calcRem(300px);
    overflow-x: hidden;
  }
  &__pill-box{
    background-color: $white;
    border: 1px solid $grey-border;
    border-radius: calcRem(6px);
    padding: calcRem(5px);
  }
  &__search-icon {
    position: absolute;
    cursor: pointer;
    top: calcRem(19px);
    right: calcRem(20px);
    width: calcRem(12px);
    height: calcRem(12px);
  }
}