@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";


.profile-container {
  position: relative;
  display: inline-block;
}

.profile-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.profile-icon img {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin-right: 8px;
}

.profile-dropdown {
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: auto;
  padding: 16px;
  z-index: 100;
}

.profile-info {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  border-radius: 8px;
  padding: 5px;
  &:hover{
    background-color: rgba(0, 123, 255, 0.10);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
}

.profile-pic-large {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 16px;
}

.profile-details strong {
  font-size: 14px;
  color: #333;
}

.profile-details p {
  font-size: 12px;
  color: #777;
  margin-top: 4px;
}

.switch-organization-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 16px;
}

.switch-organization-btn:hover {
  background-color: #0056b3;
}

.profile-info:hover .profile-details p {
  color: #555;
}