@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.view {
  background-color: $backgroundColor;
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  &__loading{
    display: none;
  }

  &__body {
    width: calc(100vw - calcRem(256px));
    margin-left: calcRem(256px);
    @include media-breakpoint-down(xl) {
      margin-left: 0;
      width: 98vw;
    }
    &__header {
      display: flex;
      flex-direction: row;
      .image-button {
        padding-left: calcRem(10px);
      }
      &-left {
        padding: calcRem(15px);
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        margin-left: calcRem(10px);
        z-index: 1;
      }
      &-right {
        padding: calcRem(15px);
        flex-grow: 1;
        justify-content: flex-end;
        display: flex;
        flex-direction: row;
        margin-right: calcRem(10px);
        z-index: 2;
        @include media-breakpoint-down(xl) {
          display: none!important;
        }
      }
    }
    &__banner{
      position:relative;
      width:100%;
      &__edit-img{
        position: absolute;
        cursor: pointer;
        width: calcRem(35px);
        height: calcRem(35px);
        top: 10%;
        left: 95%;
        transform: translate(-50%, -50%);
        text-align: center;
        @include media-breakpoint-down(lg) {
          height: calcRem(20px);
          width: calcRem(20px);
          top: 15%;
          left: 96.4%;
        }
        @include media-breakpoint-only(xs) {
          left: 95%;
        }
    }
      &__share-img{
        position: absolute;
        cursor: pointer;
        width: calcRem(35px);
        height: calcRem(35px);
        top: 10%;
        left: 90%;
        transform: translate(-50%, -50%);
        text-align: center;
        @include media-breakpoint-down(lg) {
          height: calcRem(20px);
          width: calcRem(20px);
          top: 15%;
        }
        @include media-breakpoint-only(xs) {
          left: 86%;
        }
      }
      &__background-img{
        width:100%;
        height: calcRem(377px);
        display: block;
        object-fit: cover;
        background-color: $white;
        @include media-breakpoint-down(lg) {
          height: calcRem(100px);
        }
      }
      &__line{
        width:100%;
        height: calcRem(56px);
        background-color: $offwhite;
        opacity: 0.8;
        position: absolute;
        z-index: 1;
        top: 75%;
        @include media-breakpoint-down(lg) {
          height: calcRem(21px);
          top: 72%;
        }
      }
      &__marketplace-img{
        height: calcRem(22px);
        text-align: center;
        z-index: 999;
        @include media-breakpoint-down(lg) {
          height: calcRem(10px);
        }
        &:hover{
          cursor: pointer;
        }
      }
      &__line-items{
        opacity: 1;
        text-align: center;
        margin-left:calcRem(8px);
        z-index: 999;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: calcRem(10px);
        height: calcRem(56px);
        @include media-breakpoint-down(lg) {
          height: calcRem(21px);
        }
      }
      &__arrow-img{
        height: calcRem(12px);
        text-align: center;
        z-index: 999;
        @include media-breakpoint-down(lg) {
          height: calcRem(7px);
        }
      }
      &__title{
        text-align: center;
        z-index: 999;
        color: $blue;
        font-size: $fontSizeMd;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include media-breakpoint-down(lg) {
          font-size: $fontSizeSm;
        }
      }
      &__client-logo{
        width: calcRem(116px);
        background-color: $white;
        height: calcRem(116px);
        border-radius: 50%;
        box-shadow: 0 calcRem(9px) calcRem(8px) #00000029;
        text-align: center;
        opacity: 1;
        z-index: 999;
        @include media-breakpoint-down(lg) {
          height: calcRem(31px);
          width: calcRem(31px);
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    flex-direction: column;
  }

  &__navbar {
    display: none!important;
    z-index: 2;
    .image-button {
      padding-right: calcRem(10px);
    }
    @include media-breakpoint-down(xl) {
      display: flex!important;
      align-items: center;
    }
  }

  &__navbar--border{
    border: transparent !important;
    margin-left: calcRem(10px);
  }

  &__sidebar {
    width: calcRem(256px);
    background-color: $white;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    @include media-breakpoint-down(xl) {
      display: none;
      position: inherit;
      width: 100%;
    }

    &-disabled {
      filter: grayscale(100%);
      pointer-events: none;
    }

    &__menu-divider {
      border-top: calcRem(2.5px) solid #ccc;
      margin: calcRem(10px) 0;
    }

    &__section-title {
      font-size: calcRem($fontSizeSm);
      color: $grey;
      font-family: $primaryFont;
    }

    &__logo {
      &__container {
        cursor: pointer;
        margin-left: calcRem(15px);
      }

      &__img {
        width: calcRem(91px);
        height: calcRem(56px);
        margin-right: calcRem(16px);
      }

      &__text {
        font-size: calcRem($fontSizeSm);
        color: $grey;
      }
    }

    &__span{
      font-size: calcRem($fontSizeSm);
    }

    &__footer {
      font-size: calcRem($fontSizeSm);

      &__logo {
        width: auto;
        max-width: 100%;
        height: calcRem(40px);
        margin-top: calcRem(30px);
      }

      &__content {
        margin-top: calcRem(10px);
        margin-bottom: 0;
        font-size: calcRem($fontSizeSm);
      }

      &__copyright {
        margin-top: calcRem(10px);
        margin-bottom: calcRem(10px);
        font-size: calcRem($fontSizeSm);
      }
    }

    &__side-link {
      color: $black !important;
      font-size: calcRem($fontSizeSm);

      &:hover {
        color: $blue !important;
      }

      &.active {
        color: $blue !important;
        background-color: $white !important;
        font-weight: $bold !important;
      }
    }
    &__side-brand {
      color: $grey !important;
      font-size: calcRem($fontSizeMd)!important;
    }
    &__side-search {
      color: $black !important;
      font-size: calcRem($fontSizeMd)!important;

      &:hover {
        color: $blue !important;
      }

      &.active {
        color: $blue !important;
        background-color: $white !important;
        font-weight: $bold !important;
      }
    }
  }

  .page {
    background-color: $backgroundColor;
    padding: calcRem(10px) calcRem(5px);

    .view-top {
      display: flex !important;
      flex-direction: row;
      justify-content: space-between !important;
    }

    .view-tab {
      display: flex !important;
      flex-direction: row;
      justify-content: space-between !important;
      background-color: $white;
      padding: calcRem(15px);
      -webkit-box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
      -moz-box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
      box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;

      border-radius: calcRem(13px);
      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }

      &__toggle {
        @include media-breakpoint-down(md) {
          margin-top: calcRem(15px);
        }
      }

      p {
        color: $primaryTextColor;
        font-size: $fontSizeExtraSmallSm;
        font-weight: 500;
        margin: 0;
      }

    }
  }

  .col-md-10 {
    padding: calcRem(10px);
  }
}

.card__column {
  position: sticky;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  height: calc(100vh - calcRem(227px));
}

.pb-5 {
  padding-bottom: 0 !important;
}

.reuse__row {
  justify-content: space-between;
  display: inline-flex;
}

.reuse__row__title {
  font-weight: bold;
}

.accordion {
  max-width: 100%;
}

.view_map {
  height: calc(100vh - calcRem(227px));
}

.view{
  &__map-grid--size{
    width: calcRem(130px);
    @include media-breakpoint-down(xl) {
      width: calcRem(50px);
    }
  }
}

.catalogue-item {
  font-size: 12px;

  &-disabled {
    @extend .catalogue-item;
    pointer-events: none;
    opacity: 0.5;
  }
}

.sort-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
}

.sort-button.ascending::after {
  content: " ↑";
  margin-left: 5px; /* Adjust as needed */
}

.sort-button.descending::after {
  content: " ↓";
  margin-left: 5px; /* Adjust as needed */
}

.sort-container {
  display: flex;
  flex-direction: row;
  width: 90px;

}

.sort-container-text {
  display: flex;

  margin-right: 5px;
}

.sort-dropdown {
  width: calcRem(82px) !important;
  height: calcRem(35px) !important;
  padding: calcRem(10px) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 13px !important;
}



.override {
  padding: 0 0 0 0 !important;
  margin: 0 0 0 0 !important;
}

.my-client-accordion {

  background-color: $white !important;

  :hover{
    background-color: $white !important;
    color: $blue !important;
  }

  .accordion-button{
    padding-left: 0 !important;
    background-color: $white !important;
    font-weight: normal !important;
    ::after{
      background-color: blue !important;
    }
  }

}


