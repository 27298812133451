@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.switch {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;

    input.form-check-input {
        border-radius: 30px;
        background-color: $grey;
        width: 84px;
        height: 37px;
        cursor: pointer;
    }
    input.form-check-input:checked {
        background-color: $blue;
    }
    input.form-check-input:focus {
        background-color: $blue !important;
        outline: none !important;
        color: $white;
        border: none;
    }
    .form-check-label {
        font-family: $primaryFont;
        font-weight: $semibold;
        color: $black;
        margin-left: 10px;
        margin-top: 10px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        white-space: normal;
        &.inverted {
            position: relative;
            top: 4px;
        }
    }
}

.checkbox {
    input.form-check-input {
        width: calcRem(25px);
        height: calcRem(25px);
        background-color: $white;
        box-shadow: calcRem(0px) calcRem(0px) calcRem(6px) #000000;
        color: $black;
        cursor: pointer;
    }
    input.form-check-input:checked{
        background-color: $black !important;
        outline: none !important;
        color: $white;
        border: none;
    }
    .form-check-label {
        font-family: $primaryFont;
        font-weight: $semibold;
        color: $black;
        margin-left: calcRem(5px);
        margin-top: calcRem(5px);
        &.inverted {
            position: relative;
            top: 4px;
        }
    }
}

