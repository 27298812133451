@import "../../styles/functions";
@import "../../styles/variables";
@import "../../../../node_modules/bootstrap/scss/bootstrap";

$primary-color: #1e90ff;
$secondary-color: #f8f8f8;
$hover-color: #f4f4f4;
$box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

.subscription-container {
  text-align: center;
  padding: calcRem(50px);
  font-family: 'Arial', sans-serif;
  background-color: $secondary-color;
  height: 100vh;
  overflow-y: auto ;

  h1 {
    color: $primaryTextColor;
    margin-bottom: calcRem(30px);
    font-size: $fontSizeXLg;
  }

  &__card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: calcRem(40px);
    margin-top: calcRem(30px);
  }

  &__card {
    width: calcRem(220px);
    border-radius: calcRem(15px);
    border: calcRem(1px) solid #ddd;
    padding: calcRem(20px);
    background-color: white;
    box-shadow: $box-shadow;
    transition: 0.3s;
    cursor: pointer;
    &-selected{
      border: 2px solid #28a745;
      cursor: not-allowed;
      opacity: 0.8;
    }

    &-loading {
      background-color: $hover-color;
      cursor: progress;
      opacity: 0.5;
      animation: pulse 1.5s infinite;
    }

    h2 {
      color: $primaryTextColor;
      font-size: $fontSizeXLg;
    }

    p {
      color: $secondaryTextColor;
      font-size: $fontSizeMd;
      margin-top: calcRem(10px);
    }

    &:hover {
      transform: translateY(-10px);
      background-color: $hover-color;
    }
  }

  .selected-tier {
    margin-top: calcRem(50px);
    font-weight: bold;
    color: #28a745;
    animation: fadeIn 0.5s ease-in-out;
  }
  .back {
   display:flex;
   justify-content: flex-start;
    align-content: flex-end;

  }
}

// Add a fade-in animation for selected tier text
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
