@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.carousel {
  width: 100%;
  position: relative;
  display: inline-block;
  .slick-slide > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calcRem(423px);
    margin: 0 10px;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    transform: scale(0.7);
    opacity: 0.7;
    @include media-breakpoint-down(xl) {
      height: calcRem(226px);
    }
  }
  .slick-current{
    height: calcRem(435px);
    @include media-breakpoint-down(xl) {
      height: calcRem(238px);
    }
  }

  .slick-center > div {
    transform: scale(1);
    border-radius: calcRem(8px);
    opacity: 1;
    box-shadow: 0 calcRem(7px) calcRem(6px) #00000029;
  }


  .carousel__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: calcRem(8px);
  }

  .slick-arrow{
    filter: invert(1);
  }

  .carousel__full-size {
    position: absolute;
    bottom: calcRem(10px);
    right: calcRem(10px);
    cursor: pointer;
    max-width: calcRem(39px);
    max-height: calcRem(39px);
  }
}
