@import "../../../styles/functions";
@import "../../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.auction-wizard{
  font-size: calcRem($fontSizeSm);
  &__box{
    font-size: calcRem($fontSizeSm);
    background-color: $white;
    border-radius: calcRem(6px);
    padding: calcRem(5px) calcRem(20px) calcRem(20px) calcRem(20px);
    margin-bottom: calcRem(20px);
    &--grey{
      background-color: $offwhite;
    }
  }
  &__image-container{
    padding: 0 !important;
    background-color: $offwhite;
    margin-bottom: calcRem(20px);
    border-radius: calcRem(6px);
    &__label{
      font-size: calcRem($fontSizeLg);
      width: auto;
      font-weight: $bold;
      color: $black;
      margin-bottom: calcRem(20px);
    }
  }
  &__img-fluid{
    height: calcRem(221px);
    border-radius: calcRem(6px);
    width: 100%;
    object-fit: cover;
    img{
      object-fit: contain;
    }

  }
  &__circle{
    width: calcRem(49px);
    height: calcRem(49px);
    border-radius: 50%;
    padding: calcRem(10px);
    background-color: $blue;
  }
  &__checkbox-label{
    font-family: $primaryFont;
    font-weight: $semibold;
    color: $black;
    margin-left: calcRem(5px);
    margin-top: calcRem(5px);
    text-wrap: nowrap;
  }
  &__img-placeholder{
    background-color: $grey;
    border-radius: calcRem(6px);
    height: 100%;
    @include media-breakpoint-down(md) {
      height: calcRem(226px);
    }
  }
  &__third-step{
    text-align: left;
    &__bold-title{
      font-weight: bold;
    }
    &__auction-title{
      font-size: calcRem($fontSizeLg);
      text-transform: uppercase;
      word-break: break-word;
    }
    &__auction-date{
      color: $grey;
    }
    &__entry--grey-color{
      color: $grey;
    }
  }
  &__success-title{
    font-size: calcRem($fontSizeLg);
    font-weight: bold;
  }
}