/* ClientProfile.scss */
@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";


@media (max-width: 768px) {

  .background-image {
    visibility: hidden;
  }
}

.background-image {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0; /* Ensure it's behind other content */
}

.user-profile{
  &__content{
    position: relative;
    z-index: 1;
    &--color{
      background-color: white;
    }
  }

  &__frame-avatar {
    height: calcRem(259px);
    width: calcRem(259px);
    margin-bottom: calcRem(20px);
    @include media-breakpoint-down(md) {
      height: calcRem(136px);
      width: calcRem(136px);
    }
  }

  &__frame-avatar--img{
    width: 100%;
    height: calcRem(259px);
    @include media-breakpoint-down(md) {
      height: calcRem(136px);
    }
    object-fit: cover;
    object-position: center;
    border-radius: calcRem(1000px);
    border: calcRem(14px) solid $black;
  }

  &__position{
    display: flex;
    flex-direction: row;
    justify-content: center;
    &--color{
      background-color: white;
    }
  }
  &__frame-circles{
    position: absolute;
    top: 0;
    z-index: 0;
    max-width: 100%;
    min-height: 100%;
    img{
      width: 100%;
      height: 100vh;
      @include media-breakpoint-down(xl) {
        height: auto;
      }
    }
  }
  &__section--grey{

  }
  &__button--extra-margin-top{
    margin-top: calcRem(20px);
  }
}

