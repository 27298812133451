@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.legal-disclaimer {
  font-size: 12px;
  margin-top: 20px;
  padding: 5px 0 10px 0;
  text-align: center;
}

.legal-disclaimer p {
  margin: 3px 0;
}
