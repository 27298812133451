@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.search-engine-public {
  font-size: calcRem($fontSizeSm);

  &-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }

  .custom-input {
    position: relative;
  }

  .search-engine-public.form-control {
    padding: calcRem(6px) calcRem(10px) calcRem(6px) calcRem(13px);
  }

  .form-control {
    width: 100%;
    font-size: calcRem($fontSizeMd);
  }

  &__title {
    font-size: calcRem($fontSizeLg);
    font-weight: bold;
  }

  &__title-box {
    font-size: calcRem($fontSizeMd);
    font-weight: bold;
  }

  &__item-box {
    overflow: auto;
    max-height: calcRem(300px);
    overflow-x: hidden;
  }

  &__pill-box {
    background-color: $white;
    padding: calcRem(5px);
  }

  &__search-icon {
    position: absolute;
    cursor: pointer;
    top: calcRem(19px);
    right: calcRem(20px);
    width: calcRem(12px);
    height: calcRem(12px);
  }
}

.popular_items {
  cursor: pointer;
}

.popular-search-container {
  overflow: hidden;
  transition: max-height 0.3s ease;

  &.collapsed {
    @media (max-width: 767px) {
      max-height: 60px;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      max-height: 90px;
    }

    @media (min-width: 1024px) {
      max-height: 150px;
    }
  }

  &.expanded {
    max-height: none;
  }
}

.collapse_items {
  cursor: pointer;
}
