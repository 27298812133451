@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.file-upload {

  .img-preview {
    width: calcRem(150px);
    height: calcRem(150px);
    object-fit: contain;
    border: thin dotted #DEDEDE;
  }
  &__upload-prompt{
    font-weight: bold;
    font-size: calcRem($fontSizeMd);
    color: $black;
    display: flex;
    flex-direction: column;
    text-align: center;
    &__icon{
      width: calcRem(44px);
      height: calcRem(40px);
      align-self: center;
    }
  }

  .img-delete {

    &:hover {
      cursor: pointer;
    }
    width: calcRem(30px);
    height: calcRem(30px);
    background-color: $foregroundColor;
    border-radius: 50%;
    font-size: calcRem(30px);
    color: $primaryActionColor;
    position: absolute;
    transform: translateX(calcRem(120px)) translateY(calcRem(10px));
    svg {
      margin-top: calcRem(-20px);
    }
  }

  .form-label {
    font-size: $fontSizeSmall;
    padding-left: calcRem(5px);
  }

  .form-control {
    width: 100%;
    height: calcRem(60px);
    border: thin solid transparent;
  }

  .error {
    border: calcRem(2px) solid red!important;
  }

  .error-lbl {
    color: red;
  }
}

@include media-breakpoint-down(xl) {
  .file-upload{
    &__upload-prompt{
      font-size: calcRem($fontSizeMobileMd);
    }
  }
}
