@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.input {
  .custom-input {
    position: relative;
  }

  input.form-control {
    padding: calcRem(6px) calcRem(10px) calcRem(6px) calcRem(13px);
  }

  .form-label {
    font-size: calcRem($fontSizeMd);
    padding-left: calcRem(5px);
  }

  .form-control {
    width: 100%;
    border: thin solid $grey-border;
    border-radius: calcRem(100px);
    font-size: calcRem($fontSizeMd);
  }

  .form-control:focus {
    border: thin solid $black;
    border-radius: calcRem(100px) !important;
  }

  .error {
    border: thin solid $error-red !important;
    border-radius: calcRem(100px) !important;
  }

  .units {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .units:focus {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .error-lbl {
    color: $error-red;
  }

  .error-icon {
    position: absolute;
    top: calcRem(13px);
    right: calcRem(30px);
    width: calcRem(11px);
    height: calcRem(11px);
    filter: invert(36%) sepia(94%) saturate(5159%) hue-rotate(357deg) brightness(91%) contrast(93%);
  }

  .metrics {
    display: flex;
    align-items: center;
    position: absolute;
    top: calcRem(13px);
    right: calcRem(30px);
    width: calcRem(11px);
    height: calcRem(11px);

  }

  .input-eye {
    position: absolute;
    bottom: calcRem(8px);
    right: calcRem(24px);
    width: calcRem(21px);
    height: calcRem(21px);
    cursor: pointer;
  }

  .error-container {
    border-radius: calcRem(5px);
    background-color: $error-red;
    color: $white;
    margin-left: calcRem(12px);
    padding: calcRem(5px) calcRem(10px);
    font-size: calcRem($fontSizeSm);
    animation: fadeIn 0.3s ease;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  .info-tooltip {
    padding-left: 7px;
    width: 24px;
    height: 24px;
  }

  .input-group-text {
    border-top-right-radius: calcRem(100px);
    border-bottom-right-radius: calcRem(100px);
    height: calcRem(36.5px);
  }

  .input-group-append {
    height: calcRem(22.5px);
  }

}
