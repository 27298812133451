@import "../../styles/functions";
@import "../../styles/variables";

.error-container-pwd{
  border-radius: calcRem(5px);
  background-color: $error-red;
  color: $backgroundColor;
  padding: calcRem(5px) calcRem(10px);
  font-size: $fontSizeExtraSmallSm;
  width: calcRem(173px);
  margin-bottom: calcRem(10px);
}
.valid-container-pwd{
  border-radius: calcRem(5px);
  background-color: $errorPageBackground;
  color: $backgroundColor;
  padding: calcRem(5px) calcRem(10px);
  font-size: $fontSizeExtraSmallSm;
  width: calcRem(125px);
  margin-bottom: calcRem(10px);
}