@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../styles/variables";
@import "../styles/functions";
@import "../styles/global";
@import "Dashboard";
@import "storyboard";

body {
  // background-image: url("../../../public/bg-theme.jpg");
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
  // min-height: 100vh;

}



.container {
  background-color: $backgroundColor;
}

.navbar .container {
  background-color: transparent;
}

.p-image {
  object-fit: cover;
  width: 90%;
  height: 500px;
}
