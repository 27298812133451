@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";


.force-graph-container{
  overflow:hidden;
  margin-bottom: calcRem(20px);
  width: auto;
  max-width: calcRem(430px);
  height: calcRem(430px);
}
.materials-graph{
  canvas{
    background-color: $offwhite;
    border-radius: calcRem(8px);
  }
}