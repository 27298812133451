@import "../../styles/functions";
@import "../../styles/variables";


.stepper-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  position:relative;

  margin-bottom: calcRem(45px);
  @media screen and (max-width: 800px) {
    margin-bottom: calcRem(0px);
  }
  margin-top: calcRem(16px);

  &__circle{
    width: calcRem(34px);
    height: calcRem(34px);
    border-radius: 50%;
    background-color: $grey;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-weight: bold;
    flex-shrink: 0;
    position:relative;

    &--active{
      background-color: $black;
    }

    &--success{
      background-color: $blue;
    }

    &--caution{
      background-color: #DB7F14;
    }

    &--completed {
      background-color: $green;
    }

  }

  &__line{
      border-top: calcRem(2px) solid $black;
      width: 100%;
      flex-grow: 1;
  }

  &__text{
    position: absolute;
    width:calcRem(124px);
    font-size: calcRem($fontSizeSm);
    top: calcRem(45px);
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: $black;
    line-height: calcRem(16px);

    &--success{
      color: $blue;
    }

    &--caution{
      color: #DB7F14;
    }
  }
  &__mobile-text{
    display: none;
    text-align: center;
    font-size: calcRem($fontSizeSm);
    line-height: calcRem(16px);
    color: $black;
    font-weight: bold;
    margin-top: calcRem(10px);
  }

  &__checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calcRem(16px);
    height: calcRem(16px);
  }
  &__icon-wrapper {
    text-align: center;
    margin-bottom: 20px;
  }

  &__icon {
    max-width: calcRem(115px); // Adjust this value as needed
    height: calcRem(115px);
  }
}

@media screen and (max-width: 1000px) {
  .stepper-container {
   &__circle {
      height: calcRem(26px);
      width: calcRem(26px);
    }

    &__text {
      width: calcRem(124px);
      font-size: calcRem(10px);
      top: calcRem(40px);
    }

    &__icon {
      max-width: calcRem(75px); // Adjust this value as needed
      height: calcRem(75px);
    }
  }
}
@media screen and (max-width: 800px) {
  .stepper-container {
    &__text {
      display: none;
    }
    &__mobile-text{
      display: block;
    }

    &__circle:hover &__text {
      display: flex;
      background-color: $white;
      border-radius: calcRem(5px);
      justify-content: center;
      z-index: 999;
    }
    &__icon {
      max-width: calcRem(50px); // Adjust this value as needed
      height: calcRem(50px);
    }

  }
}





