@import "../../styles/functions";
@import "../../styles/variables";

.location-text {
    text-align: left;
    font-size: calcRem($fontSizeSm);
    color: $black;
}

canvas {
    background-color: transparent;
}

.map-container {
    transition: height 0.5s ease, opacity 0.5s ease, margin-bottom 0.5s ease;
    overflow: hidden; /* Prevents content overflow during the transition */
}

.map-container.hidden {
    height: 0;
    opacity: 0;
    margin-bottom: 0;
    pointer-events: none; /* Optional: to disable interactions when hidden */
}

.map-container.visible {
    height: 550px; /* or whatever height you need */
    opacity: 1;
    margin-bottom: 30px; /* Ensure this matches the desired spacing when visible */
}
