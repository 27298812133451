@import "../../../styles/functions";
@import "../../../styles/variables";

.proposal-info{
  &__title{
    font-size: $fontSizeMobileLg;
    font-weight: bold;
  }

  &__subtitle{
    font-size: $fontSizeMd;
    font-weight: bold;
  }

  &__description{
    font-size: $fontSizeMd;
    color: $grey;
  }

  &__line{
    border-bottom: calcRem(3px) solid $blue;
    margin-bottom: calcRem(20px);
  }
}