@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.text-area {
  .custom-input{
    position: relative;
  }
    .custom-input input:hover + .text-muted,.custom-input input:active + .text-muted,.custom-input input:focus + .text-muted{
      visibility: visible;
      opacity: 1;
      transition: visibility 0s, opacity 0.1s linear;
    }

    input.form-control {
      padding: 6px 10px 6px 13px;
    }

    .form-label {
        font-size: $fontSizeMd;
        padding-left: calcRem(5px);
        margin-top: 0;
      }

      .form-control {
        width: 100%;
        border: thin solid $grey-border;
        border-radius: calcRem(8px)!important;
      }
      .form-control:focus {
        border: thin solid $black;
        border-radius: calcRem(8px)!important;
      }

      .error {
        border: thin solid $error-red!important;
        border-radius: calcRem(8px)!important;
      }

      .error-lbl {
        color: $error-red;
      }

      .error-container{
        border-radius: calcRem(6px);
        border: calcRem(1px) transparent;
        background-color: $error-red;
        color: $white;
        margin-left: calcRem(12px);
        padding: 4px 8px 4px 8px;
        font-size: $fontSizeSm;
      }

      .text-muted{
        visibility: hidden;
        opacity: 0;
      }
}

@include media-breakpoint-down(xl) {
  .text-area{
    .error-container{
      font-size: $fontSizeMobileSm;
    }
  }
}

.input-eye{
  align-self: center;
  height: calcRem(32px);
}

