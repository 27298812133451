@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.search-box {

  position: relative;
  .form-control:focus::placeholder {
    color: #6c757d;
  }
  .form-control {
    background-color: #d1e9f4;
  }
  .input-group-text {
    position: absolute;
    left: calc(100% - 2.5rem);
    top: 0.5rem;
    border: none;
    background: none;
  }
}

body {
  height: 100%;
}
.landing-page-bg {
  background-color: $backgroundColor;
  background-position: center center;
  background-size: cover;
}
.main-content {
  flex: 1;
}
.view-landing-page {

  &__loading{
    display: none;
  }

  @include media-breakpoint-down(xl) {
    flex-direction: column;
  }

  &__navbar--border{
    border: transparent !important;
    margin-left: calcRem(10px);
  }

  &__sidebar {
    width: calcRem(256px);
    background-color: $white;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    @include media-breakpoint-down(xl) {
      display: none;
      position: inherit;
      width: 100%;
    }

    &-disabled {
      filter: grayscale(100%);
      pointer-events: none;
    }

    &__menu-divider {
      border-top: calcRem(2.5px) solid #ccc;
      margin: calcRem(10px) 0;
    }

    &__section-title {
      font-size: calcRem($fontSizeSm);
      color: $grey;
      font-family: $primaryFont;
    }

    &__logo {
      &__container {
        cursor: pointer;
        margin-left: calcRem(15px);
        @media (max-width: 768px) {
          margin-left: 0;
        }
      }

      &__img {
        width: calcRem(91px);
        height: calcRem(56px);
        margin-right: calcRem(16px);
        @media (max-width: 768px) {
          width: calcRem(63.7px);
          height: calcRem(39.2px);
          margin-right: calcRem(11px);
        }
      }

      &__text {
        font-size: calcRem($fontSizeSm);
        color: $grey;
      }
    }

    &__footer {
      font-size: calcRem($fontSizeSm);

      &__logo {
        width: auto;
        max-width: 100%;
        height: calcRem(40px);
        margin-top: calcRem(30px);
      }

      &__content {
        margin-top: calcRem(10px);
        margin-bottom: 0;
        font-size: calcRem($fontSizeSm);
      }

      &__copyright {
        margin-top: calcRem(10px);
        margin-bottom: calcRem(10px);
        font-size: calcRem($fontSizeSm);
      }
    }

    &__side-link {
      color: $black !important;
      font-size: calcRem($fontSizeSm);

      &:hover {
        color: $blue !important;
      }

      &.active {
        color: $blue !important;
        background-color: $white !important;
        font-weight: $bold !important;
      }
    }
    &__side-brand {
      color: $grey !important;
      font-size: calcRem($fontSizeMd)!important;
    }
    &__side-search {
      color: $black !important;
      font-size: calcRem($fontSizeMd)!important;

      &:hover {
        color: $blue !important;
      }

      &.active {
        color: $blue !important;
        background-color: $white !important;
        font-weight: $bold !important;
      }
    }
  }

  .page {
    background-color: $backgroundColor;
    padding: calcRem(10px) calcRem(5px);

    .view-top {
      display: flex !important;
      flex-direction: row;
      justify-content: space-between !important;
    }

    .view-tab {
      display: flex !important;
      flex-direction: row;
      justify-content: space-between !important;
      background-color: $white;
      padding: calcRem(15px);
      -webkit-box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
      -moz-box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
      box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;

      border-radius: calcRem(13px);
      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }

      &__toggle {
        @include media-breakpoint-down(md) {
          margin-top: calcRem(15px);
        }
      }

      p {
        color: $primaryTextColor;
        font-size: $fontSizeExtraSmallSm;
        font-weight: 500;
        margin: 0;
      }

    }
  }

  .col-md-10 {
    padding: calcRem(10px);
  }
}

.card__column {
  position: sticky;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  height: calc(100vh - calcRem(227px));
}

.pb-5 {
  padding-bottom: 0 !important;
}

.reuse__row {
  justify-content: space-between;
  display: inline-flex;
}

.reuse__row__title {
  font-weight: bold;
}

.accordion {
  max-width: 100%;
}

.view_map {
  height: calc(100vh - calcRem(227px));
}

.view{
  &__map-grid--size{
    width: calcRem(130px);
    @include media-breakpoint-down(xl) {
      width: calcRem(50px);
    }
  }
}

.catalogue-item {
  font-size: 12px;

  &-disabled {
    @extend .catalogue-item;
    pointer-events: none;
    opacity: 0.5;
  }
}

.sort-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
}

.sort-button.ascending::after {
  content: " ↑";
  margin-left: 5px; /* Adjust as needed */
}

.sort-button.descending::after {
  content: " ↓";
  margin-left: 5px; /* Adjust as needed */
}

.sort-container {
  display: flex;
  flex-direction: row;
  width: 90px;

}

.sort-container-text {
  display: flex;

  margin-right: 5px;
}

.sort-dropdown {
  width: calcRem(82px) !important;
  height: calcRem(35px) !important;
  padding: calcRem(10px) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 13px !important;
}



.override {
  padding: 0 0 0 0 !important;
  margin: 0 0 0 0 !important;
}

.my-client-accordion {

  background-color: $white !important;

  :hover{
    background-color: $white !important;
    color: $blue !important;
  }

  .accordion-button{
    padding-left: 0 !important;
    background-color: $white !important;
    font-weight: normal !important;
    ::after{
      background-color: blue !important;
    }
  }

}

