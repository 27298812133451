@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.edit-card{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: $offwhite;
  padding: calcRem(19px);
  border-radius: calcRem(6px);

  &__img-container{
  margin-right: calcRem(10px);
  }
  &__img{
    height: calcRem(140px);
    width: calcRem(140px);
    object-fit: cover;
    border: calcRem(1px) solid transparent;
    border-radius: calcRem(6px);
  }
  &__details{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &__title{
    font-size: $fontSizeMobileMd;
    color: black;
    font-weight: bold;
    margin-bottom:calcRem(20px);
    text-transform: uppercase;
    word-break: break-word;
  }

}

@include media-breakpoint-down(xl) {
  .edit-card{
    &__img{
      height: calcRem(72px);
    }
    &__title{
      margin-bottom:calcRem(10px);
    }
  }
}

@include media-breakpoint-down(sm) {
  .edit-card{
    flex-direction: column;
  }
}