@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.client-selector {
  border: calcRem(2px) solid $blue;
  border-radius: calcRem(8px);
  padding: calcRem(16px);
  margin-bottom: calcRem(20px);
  box-shadow: 0 calcRem(4px) calcRem(8px) rgba(0, 0, 0, 0.1);

  &__label{
    font-size: $fontSizeLg;
    font-weight: bold;
  }
}