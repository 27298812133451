@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";



.solution{
  &__container{
    background-color: $offwhite;
    margin-top: calcRem(14px);
    margin-bottom: calcRem(24px);
    padding: calcRem(20px);
    border-radius: calcRem(6px);
  }
  &____description{
    color: $primaryActionColor;
    margin-top: calcRem(20px);
    height:auto;
    margin-bottom: calcRem(20px);
  }
}

.valuation {
  &__title{
    font-size: calcRem($fontSizeLg);
    font-weight: $bold;
  }
  &__description{
    font-size: calcRem($fontSizeMd);
  }
  &__location{
    color: $blue;
    font-size: calcRem($fontSizeMd);
  }
  &__category__container {
    padding: calcRem(20px);
    background-color: $white;
    border-radius: calcRem(6px);
    min-width: 48%;
    width: 48%;
  }
}

.relative{
  color: $black;
  font-size: calcRem($fontSizeSm);

  &__title{
    display: flex;
    align-items: center;
    gap: calcRem(10px);
    font-size: calcRem($fontSizeMd);
    font-weight: $bold;
    margin-bottom: calcRem(20px);
  }
  &__impact{
    font-size: calcRem($fontSizeMd);
  }
  &__subtitle{
    font-weight: $bold;
    font-size: calcRem($fontSizeSm);
    display: flex;
    align-items: center;
    gap: calcRem(10px);
    margin-top: calcRem(20px);
    margin-bottom: calcRem(10px);
  }
}