@import "../../styles/functions";
@import "../../styles/variables";
.read-more-less {
  position: relative;
}

.read-more {
  cursor: pointer;
  color: $grey;
  font-weight: 700;
  border: none;
  background: none;
}

.read-more-text {
  color: $black;
}

.tooltip-inner {
  color: $black !important;
  background-color: $white !important;
  font-size: calcRem(12px);
}

