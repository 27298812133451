@import "../../styles/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/bootstrap";

.verify-box{
  margin-bottom: calcRem(10px);
  background-color: $foregroundColor;
  border-radius: calcRem(14px);
  -webkit-box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 calcRem(2px) calcRem(5px) 0 rgba(0, 0, 0, 0.2) !important;
  height: calc(100% - calcRem(30px));
}