//------------ CONFIGURATION -----------------//
:root {
  //Fallback values - identical with EME's configuration
  --backgroundColor: #f5f5f5;
  --foregroundColor: #ffffff;
  --primaryActionColor: #1f4b86;
  --secondaryActionColor: #3b7fc7;
  --successColor: #388924;
  --warningColor: #ffb824;
  --dropdownColor: #d2d1d6;
  --primaryTextColor: #2f71be;
  --secondaryTextColor: #212126;
  $actionColor: linear-gradient(266deg, #1f4b86, #3b7fc7);
}


$black: #000000;
$white: #fff;
$red : red;
$materialsRed: linear-gradient(266deg, #e36512, #c40f0f);
$materialsOrange: linear-gradient(266deg, #b268b2, #55035d);
$materialsBlue: linear-gradient(266deg, #02194b, #6960e7);
$materialsGreen: linear-gradient(266deg, #22a454, #005054);
$materialsGrey: linear-gradient(266deg, #A9A9A9, #808080);

//Timeline Colors
$before: #fce6e8;
$after: #e8fdee;

//Error Page
$errorPageBackground: #22a454;
$errorPageButton:#005054 ;
$errorPageButtonHover: #3ba8c9;
// Client Colors (Enfield)
$backgroundColor: var(--backgroundColor);
$foregroundColor: var(--foregroundColor);
$primaryActionColor: var(--primaryActionColor);
$secondaryActionColor: var(--secondaryActionColor);
$successColor: var(--successColor);
$warningColor: var(--warningColor);
$dropdownColor: var(--dropdownColor);
$primaryTextColor: var(--primaryTextColor);
$secondaryTextColor: var(--secondaryTextColor);
$actionColor: linear-gradient(266deg, $secondaryActionColor 0%, $primaryActionColor 100%);

//// Group Colors (EME)
//$primaryAdminColor: #c82621;
//$secondaryAdminColor: #000;
//$gradientAdminColorA: #c82621;
//$gradientAdminColorB: #851916;
//$actionAdminColor: linear-gradient(266deg, $gradientAdminColorA 0%, $gradientAdminColorB 100%);
///**
//$primaryAdminColor: #2F71BE;
//$secondaryAdminColor: #000;
//$gradientAdminColorA: #3B7FC7;
//$gradientAdminColorB: #1F4B86;
//$actionAdminColor: linear-gradient(266deg, $gradientAdminColorA 0%, $gradientAdminColorB 100%);
// */
//$backgroundAdminColor: #f5f5f5;
//$foregroundAdminColor: #fff;
//$successAdminColor: #388924;
//$warningAdminColor: #ffb824;
//$placeholderAdminColor: #919191;
//$tableStripedColor: #e6f2ff;


$primaryFont: 'Helvetica Neue', sans-serif;

$thin: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

$fontSizeExtraExtraSmall: 7px;
$fontSizeExtraSmallSmXs: 10px;
$fontSizeExtraSmallSm: 12px;
$fontSizeExtraSmall: 14px;
$fontSizeSmall: 18px;
$fontSizeMedium: 22px;
$fontSizeSemiLarge: 26px;
$fontSizeLarge: 30px;
$fontSizeExtraLarge: 34px;





//$backgroundColor: var(--backgroundColor);
//$foregroundColor: var(--foregroundColor);
//$primaryActionColor: var(--primaryActionColor);
//$secondaryActionColor: var(--secondaryActionColor);
//$successColor: var(--successColor);
//$warningColor: var(--warningColor);
//$dropdownColor: var(--dropdownColor);
//$primaryTextColor: var(--primaryTextColor);
//$secondaryTextColor: var(--secondaryTextColor);


$offwhite: #f5f5f5;
$blue: #1488C7;
$black: #2c2c2c;
$grey: #8d8d8d;
$white: #ffffff;
$lightBlue: #d2e8f4;
$lightGreen: #D2F4E2;
$green: #14C730;
$lightRed: #F4DED2;
$error-red: #C71414;
$grey-border: #D9D9D9;
$shadow-black: rgba(0, 0, 0, 0.1);
$shadow-black-shady: rgba(0, 0, 0, 0.5);

$fontSizeXXLg: 35px;
$fontSizeXLg: 20px;
$fontSizeLg: 18px;
$fontSizeMd: 15px;
$fontSizeSm: 12px;

$abbCautionOrange: #fcd3a3;
$borderCautionOrange: #DB7F14;

$fontSizeMobileSm: 10px;
$fontSizeMobileMd: 15px;
$fontSizeMobileLg: 20px;

$card-border-radius: 8px;